import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneCallBack from '@mui/icons-material/PhoneCallback';
import KioskButton from '../theme/Button';
import { useStateValue } from '../store';

export default function DialogCustomerService(props) {
    const { handleClose, open } = props;
    const [{ languages }] = useStateValue();

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'md'}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    zIndex: 20,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <DialogTitle sx={{ color: 'black' }}>
                        <FormattedMessage id="customerService" defaultMessage="Customer Service" />
                    </DialogTitle>
                    <IconButton aria-label="delete" onClick={handleClose}>
                        <CancelIcon style={{ width: 40, height: 40 }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: 'calc(100% - 60px)',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <br />
                    <p style={{ fontSize: 28 }}>
                        The Customer Relations Center is open daily between{' '}
                        <strong>6:00am - 10:30pm</strong>. After hours, our calls roll over to the
                        Airport Operations Communication Center.
                    </p>
                    <br />
                    <div style={{ width: '80%', margin: '0 auto' }}>
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#39d355"
                            size="large"
                            startIcon={<PhoneCallBack />}
                            href={`https://airportcall.worldwidetechconnections.com/?local=${languages.defaultLanguage}&remote=english-united-states&number=17207304359&autoStart=true&showKeypad=true&showInstructions=true&direct=true`}
                        >
                            Go to Call
                        </KioskButton>
                    </div>
                    <br />
                    <br />
                </Box>
            </Box>
        </Dialog>
    );
}
